












































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Machine} from "@/models/machine";
import moment from "moment";

@Component({})
export default class ConfirmExportDialogComponent extends Vue {

  @Prop({default: ''})
  public title!: string;

  @Prop({default: false})
  public showYears!: boolean;



  @Prop({default: () => []})
  public multiple!: Machine[];

  public items: any = []

  public language: string = 'de';

  public selected: string[] = [];
  public selectedYear: number = 2023;

  public confirmedWithImage() {
    this.$emit('closeDialog', {withImages: true, language: this.language, items: this.selected});
  }

  public mounted() {
    this.items = this.convertToTreeViewObject();
  }

  public reloadItems() {
    this.items = this.convertToTreeViewObject();
  }


  public selectAll() {
    this.selected = this.getLeafNodes(this.items);
  }

  public deselectAll() {
    this.selected = [];
  }



  public getLeafNodes(items: any[]): any[] {
    const leafNodes: any[] = [];

    function traverse(nodes: any[]) {
      nodes.forEach((node) => {
        if (!node.children || node.children.length === 0) {
          leafNodes.push(node.id);
        } else {
          traverse(node.children);
        }
      });
    }

    traverse(items);

    return leafNodes;
  }

  public convertToTreeViewObject() {
    let m: any = []
    this.multiple.map(item => {

      item.maintenances.map(maint => {
        if (this.inSelectedYear(maint.createdAt)) {
          m.push(
              {
                id: maint.id,
                name: `TagNo - ${item.tagNo}`
              }
          )
        }
      })

/*
      if (item.maintenances.length > 1) {
        let junus: any = item.maintenances.map(maint => {

              if(this.inSelectedYear(maint.createdAt)) {

                return {
                  id: maint.id,
                  name: `Abgeschl. Wartung: ${moment(maint.createdAt).format('ll')}`
                }
              }


            }
        )

        console.log(junus)





        let k: any = {
          id: item.id,
          name: `TagNo - ${item.tagNo}`,
          children: junus
        }
        m.push(k)

        console.log(m)

      }
      else {
        if (this.inSelectedYear(item.maintenances[0].createdAt)) {
          m.push(
              {
                id: item.maintenances[0].id,
                name: `TagNo - ${item.tagNo}`
              }
          )
        }

      }
*/

    })
    return m;
  }

  public inSelectedYear(socke: any) {
    var momentDate = moment(socke);
    var startDate = moment(this.selectedYear, 'YYYY');
    var endDate = moment(this.selectedYear+1, 'YYYY');

    return momentDate.isBetween(startDate, endDate);
  }

  public confirmedWithoutImage() {
    this.$emit('closeDialog', {withImages: false, language: this.language, items: this.selected});
  }

  public closed() {
    this.$emit('closeDialog');
  }

}

